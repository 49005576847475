<template>
  <section class="binder-item-card" :class="{ '-selected': selected }" @click="clickHandler">
    <div class="detail">
      <div class="thumbnail">
        <img :src="item.doc.thumbnailURI || noImage" class="image" :alt="`${item.doc.title}の表紙画像`" />
        <div v-if="item.type === 'bookmark' && item.viewType === 'pdf'" class="type-label -pdf">PDF</div>
      </div>
      <section class="information" :class="hideMenu && '-hideMenu'">
        <p class="bookTitle">{{ item.doc.title }}</p>
        <p class="updatedAt">{{ updatedAt }}更新</p>
        <p class="sectionTitle">
          {{ item.sectionTitle }}
        </p>
        <p v-if="item.type === 'highlight'" class="citation">
          <span class="ellipsis-wrapper">{{ item.highlightedText }}</span>
        </p>
      </section>
    </div>
    <div v-if="item.description !== ''" class="memoWrapper">
      <v-icon size="16">mdi-file-document</v-icon>
      <p class="memo">
        {{ item.description }}
      </p>
    </div>
    <v-menu v-if="!hideMenu" offset-y>
      <template #activator="{ on }">
        <v-icon class="binder-item-menu" left v-on="on">mdi-dots-horizontal</v-icon>
      </template>
      <v-list dense>
        <v-list-item @click="$emit('share')">
          <v-icon left size="18">mdi-share-variant</v-icon>
          <v-list-item-content>
            <v-list-item-title> 共有 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$emit('edit')">
          <v-icon left size="18">mdi-file-document</v-icon>
          <v-list-item-content>
            <v-list-item-title> メモを編集 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="$emit('delete')">
          <v-icon left size="18">mdi-delete</v-icon>
          <v-list-item-content>
            <v-list-item-title> 削除 </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </v-menu>
  </section>
</template>

<script lang="ts">
import { BinderItem } from '@/types/binder-folders';
import { formatYmd } from '@/utility';
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import NO_IMAGE from '@/assets/noimage.png';

@Component
export default class BinderItemCard extends Vue {
  @Prop()
  item!: BinderItem;

  @Prop()
  selected!: boolean;

  @Prop({ default: false }) hideMenu!: boolean;

  /** 書影がない文献に使われるサムネイルの代替画像 */
  readonly noImage = NO_IMAGE;

  clickHandler() {
    this.$emit('click');
  }

  itemShare(e: Event) {
    this.$emit('share-item');
  }

  itemPrint(e: Event) {
    this.$emit('print-item');
  }

  get updatedAt(): string {
    return formatYmd(new Date(this.item.updatedAt * 1000));
  }
}
</script>

<style lang="scss" scoped>
.binder-item-card {
  position: relative;
  background: white;
  padding: 10px;
  margin-bottom: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: #f5f5f5;
  }

  &.-selected {
    background: #e8eff9;
  }

  > .detail {
    display: flex;
    align-items: flex-start;
    justify-content: stretch;

    > .thumbnail {
      flex-grow: 0;
      flex-shrink: 0;
      width: 40px;
      margin-right: 10px;

      > .image {
        width: 40px;
        height: auto;
      }
    }

    > .information {
      flex-grow: 1;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      > .bookTitle,
      > .updatedAt {
        font-size: 13px;
        color: #8f8f8f;
        margin: 0;
      }

      > .sectionTitle {
        flex-basis: 100%;
        font-size: 16px;
        font-weight: normal;
        margin: 3px 0 8px;
      }

      > .updatedAt,
      > .sectionTitle {
        padding-right: 50px;
      }

      > .citation {
        flex-grow: 0;
        font-size: 14px;
        margin: 0;

        // FIXME: flexbox との組み合わせでうまく ellipsis が効かない
        > .ellipsis-wrapper {
          display: block;
          height: 1.6em;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      &.-hideMenu {
        > .updatedAt,
        > .sectionTitle {
          padding-right: 0;
        }
      }
    }
  }

  > .memoWrapper {
    display: flex;
    align-items: flex-start;
    border-top: 1px solid #e0e0e0;
    margin: 12px 0 0;
    padding: 8px 0 0;
    font-size: 14px;
    color: #8f8f8f;

    > .v-icon {
      padding-top: 4px;
    }

    > .memo {
      padding: 0 0 0 4px;
      margin: 0;
    }
  }
}

.binder-item-menu {
  position: absolute;
  top: 14px;
  right: 8px;
}

.type-label.-pdf {
  width: 40px;
  padding: 0;
  text-align: center;
  font-weight: bold;
  font-size: 13px;
  border: 1px solid #efefef;
  color: #999;
}
</style>
